<template>
  <v-layout align-center>
    <DefaultButton class="pa-0 ma-0 px-4" primary @click.prevent="selectFile">{{
      label
    }}</DefaultButton>
    <div :class="['body-2 mx-4 font-weight-bold', `${color}--text`]">
      {{ selectedFile }}
    </div>
    <input
      ref="file"
      type="file"
      style="display: none"
      :accept="accept"
      @change="handleFile"
    />
  </v-layout>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "certyfikat TLS",
    },
    value: {},
    accept: {
      type: String,
      default: ".p12",
    },
  },
  computed: {
    state() {
      if (typeof this.value == "string" && this.value != "") {
        return "saved";
      } else if (this.value) {
        return "chosen";
      } else {
        return "none";
      }
    },
    label() {
      switch (this.state) {
        case "saved":
          return `Usuń ${this.type}*`;
        case "chosen":
          return `Zmień ${this.type}*`;
        default:
          return `Kliknij by dodać ${this.type}`;
      }
    },
    selectedFile() {
      switch (this.state) {
        case "saved":
          return `Plik zapisany.`;
        case "chosen":
          return this.value.name;
        default:
          return "Brak pliku.";
      }
    },
    color() {
      switch (this.state) {
        case "saved":
          return "green";
        case "chosen":
          return "black";
        default:
          return "error";
      }
    },
  },
  methods: {
    selectFile() {
      if (this.value) {
        this.$emit("update:value", "");
      } else {
        this.$refs.file.value = "";
        this.$refs.file.click();
      }
    },
    handleFile(e) {
      this.$emit("update:value", e.target.files[0]);
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
  },
};
</script>